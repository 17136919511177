import React from 'react';
import aetna from '../../images/carriers/aetna-colorful.svg';
import kaiser from '../../images/carriers/kaiser-colorful.svg';
import cigna from '../../images/carriers/cigna-colorful.svg';
import unitedHealthcare from '../../images/carriers/uhc-colorful.svg';
import humana from '../../images/carriers/humana-colorful.svg';

const logoData = [
  { name: 'aetna logo', src: aetna },
  { name: 'kaiser logo', src: kaiser },
  { name: 'cigna logo', src: cigna },
  { name: 'unitedHealthcare logo', src: unitedHealthcare },
  { name: 'humana logo', src: humana },
];

const shiftForward = () => {
  const logoDiv = document.getElementById('carrier-logos');
  const child = logoDiv.firstChild;
  logoDiv.removeChild(child);
  logoDiv.append(child);
};

const shiftBackward = () => {
  const logoDiv = document.getElementById('carrier-logos');
  const child = logoDiv.lastChild;
  logoDiv.insertAdjacentElement('afterbegin', child);
};

const CarrierLogos = () => (
  <section className="px-3 py-3 md:px-6 text-gray-dark bg-white font-poppin md:py-6">
    <div className="max-w-screen-xl overflow-hidden text-center mx-auto">
      <div className="mx-auto mb-3" data-qa="CarrierLogos:Container">
        <div className="hidden md:flex lg:flex max-w-screen-xl mx-auto flex lg:flex-nowrap justify-evenly align-center">
          <div className="md:w-[112px] md:h-[32px] lg:w-[180px] lg:h-[64px] xl:w-[200px] flex justify-center items-center">
            <img className="h-full" alt="aetna logo" src={aetna} />
          </div>
          <div className="md:w-[112px] md:h-[32px] lg:w-[180px] lg:h-[64px] xl:w-[200px] flex justify-center items-center">
            <img className="h-full" alt="kaiser logo" src={kaiser} />
          </div>
          <div className="md:w-[112px] md:h-[32px] lg:w-[180px] lg:h-[64px] xl:w-[200px] flex justify-center items-center">
            <img className="h-full" alt="cigna logo" src={cigna} />
          </div>
          <div className="md:w-[112px] md:h-[32px] lg:w-[180px] lg:h-[64px] xl:w-[200px] flex justify-center items-center">
            <img className="h-full" alt="unitedHealthcare logo" src={unitedHealthcare} />
          </div>
          <div className="md:w-[112px] md:h-[32px] lg:w-[180px] lg:h-[64px] xl:w-[200px] flex justify-center items-center">
            <img className="h-full" alt="humana logo" src={humana} />
          </div>
        </div>

        <div className="md:hidden lg:hidden mx-auto flex justify-center align-center">
          <img className="h-8 w-[112px] mr-6" alt="aetna logo" src={aetna} />
          <img className="h-8 w-[112px]" alt="kaiser logo" src={kaiser} />
        </div>
        <div className="md:hidden lg:hidden mx-auto flex justify-center align-center mt-3">
          <img className="h-8 w-[112px] mr-6" alt="cigna logo" src={cigna} />
          <img className="w-[112px] h-8" alt="unitedHealthcare logo" src={unitedHealthcare} />
        </div>
        <div className="md:hidden lg:hidden mx-auto flex justify-center align-center mt-3">
          <img className="w-[112px] h-8" alt="humana logo" src={humana} />
        </div>

        <div className="hidden md:hidden lg:hidden max-w-screen-xl mx-auto flex flex-wrap lg:flex-nowrap justify-evenly align-center mt-2 pb-8">
          <div className="flex">
            <div
              className="items-center flex pr-[16px]"
              tabIndex={-1}
              role="button"
              onClick={shiftBackward}
              onKeyDown={shiftBackward}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.99929 6L8 2.00094L6.00035 0L2.79116e-07 6L6.00035 12L8 9.99906L3.99929 6Z"
                  fill="#333333"
                />
              </svg>
            </div>

            <div id="carrier-logos" className="flex flex-row overflow-hidden">
              {logoData.map((logo, index) => {
                return (
                  <img
                    key={`carrier-logo-${logo.name}`}
                    className="mr-[24px] w-[72px]"
                    alt={logo.name}
                    src={logo.src}
                  />
                );
              })}
            </div>
            <div
              className="flex items-center pl-[16px]"
              role="button"
              tabIndex={0}
              onClick={shiftForward}
              onKeyDown={shiftBackward}
            >
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.00071 6L0 2.00094L1.99965 0L8 6L1.99965 12L0 9.99906L4.00071 6Z"
                  fill="#000060"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div>
        <span className="md:inline block text-center text-16 leading-24 xl:text-18 xl:leading-28 text-green-darker">
          <a
            href="/about-ehealth/carrier-partners"
            data-qa="view-full-carrier-list-page"
            data-wa-link="carrier logos: view all insurance carriers"
            target="_blank"
            className="block xl:inline underline text-green-800"
          >
            View all insurance carriers
          </a>
        </span>
      </div>
    </div>
  </section>
);

export default CarrierLogos;
